import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { createAndRedirectToNewHub } from "../utils/phoenix-utils";
import { Button } from "../react-components/input/Button";
import styles from "../react-components/input/Button.scss";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import { ReactComponent as CaretDownIcon } from "../react-components/icons/CaretDown.svg";
import { AuthContext } from "../react-components/auth/AuthContext";

export function CreateHomepageDropdownTutorials() {
    const breakpoint = useCssBreakpoints();
    const auth = useContext(AuthContext);
    let firstTimeClickingButton = true;

    function toggleOptions() {
        let optionsStyle = document.getElementById("options2").style;
        let wasVisible;
        if (optionsStyle.display == "block") {
            wasVisible = true;
        } else {
            wasVisible = false;
        }
        if (!wasVisible) {
            optionsStyle.display = "block";
        } else {
            optionsStyle.display = "none";
        }

        let optionsOtherDropdown = document.getElementById("options");
        if (optionsOtherDropdown) {
            if (optionsOtherDropdown.style.display == "block") { // If the other dropdown is open, close it.
                optionsOtherDropdown.style.display = "none"; 
            }
        }

        if (firstTimeClickingButton == true) { // If this is the first time clicking the button, re-run the toggle once, since it can't find the options element yet the first time. 
            firstTimeClickingButton = false;
            let secondButton = document.getElementById("options2").children[1]; // Reset the padding of the second button, because this one is unnecessarily wide.
            secondButton.style.paddingLeft = 0;
            secondButton.style.paddingRight = 0;
            if (!wasVisible) {
                optionsStyle.display = "block";
            } else {
                optionsStyle.display = "none";
            }
        } 

    }

    return (
        <div>
            <Button
                className={styles.dropdownButton}
                thick={breakpoint === "sm" || breakpoint === "md"}
                xl={breakpoint !== "sm" && breakpoint !== "md"}
                preset="landing"
                onClick={e => {
                e.preventDefault();
                // createAndRedirectToNewHub(null, null, false);
                toggleOptions();
                }}
            >
                Tutorials
                <CaretDownIcon />
            </Button>

            {/* Create an option / dropdown element for each scene in the list. */}
            <div id="options2" className={styles.options}>
                <Button
                    thick
                    xl={breakpoint !== "sm" && breakpoint !== "md"}
                    preset="signin"
                    className={styles.optionsButtons}
                    onClick={e => {
                        e.preventDefault();
                        window.open('https://www.youtube.com/playlist?list=PLk58K5q-RaTGRqe3pfzjEkujSl_OUh-iX', '_blank').focus();
                    }}
                    >
                    Basics Video
                </Button>

                <Button
                    thick
                    xl={breakpoint !== "sm" && breakpoint !== "md"}
                    preset="signin"
                    className={styles.optionsButtons}
                    onClick={e => {
                        e.preventDefault();
                        window.open('https://www.youtube.com/playlist?list=PLk58K5q-RaTGRqe3pfzjEkujSl_OUh-iX', '_blank').focus();
                    }}
                    >
                    In-depth Video's
                </Button>

                <Button
                    thick
                    xl={breakpoint !== "sm" && breakpoint !== "md"}
                    preset="signin"
                    className={styles.optionsButtons}
                    onClick={e => {
                        e.preventDefault();
                        if (!auth.isSignedIn) {
                            document.location = "/signin"; // If not signed in, send to sign in page first, so that the user will have all the necessary functionalities.
                        } else {
                            createAndRedirectToNewHub(null, "r8faLv5", false);
                        }
                    }}
                    >
                    Experience
                </Button>
            </div>
        </div>
    );
}