import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { MediaGrid } from "../room/MediaGrid";
import { MediaTile } from "../room/MediaTiles";
import { PageContainer } from "../layout/PageContainer";
import { scaledThumbnailUrlFor } from "../../utils/media-url-utils";
import { Column } from "../layout/Column";
import { Container } from "../layout/Container";
// Nigel //////////////////////////////////////////////////////////////////////////////
import { CreateHomepageDropdown } from "../../BoldlyXR/CreateHomepageDropdown";
import { CreateHomepageDropdownTutorials } from "../../BoldlyXR/CreateHomepageDropdownTutorials";
import { CreateEventWizard } from "../../BoldlyXR/CreateEventWizard";
import { CreateHomepageButton } from "../../BoldlyXR/CreateHomepageButton";
//////////////////////////////////////////////////////////////////////////////////////
import { SocialBar } from "../home/SocialBar";
import { SignInButton } from "./SignInButton";
import { AppLogo } from "../misc/AppLogo";
import { isHmc } from "../../utils/isHmc";
import maskEmail from "../../utils/mask-email";

export function HomePage() {
  const auth = useContext(AuthContext);
  const intl = useIntl();

  // preHomepagePopup screen.        Nigel.

  const { results: favoriteRooms } = useFavoriteRooms();
  // const { results: publicRooms } = usePublicRooms();

  // Get a set of public rooms, 24 rooms each time.             (There was no prettier way that worked.)
  let chunckOfRooms0 = Array.from((usePublicRooms(0).results));
  let chunckOfRooms1 = Array.from((usePublicRooms(1).results));
  let chunckOfRooms2 = Array.from((usePublicRooms(2).results));
  let chunckOfRooms3 = Array.from((usePublicRooms(3).results));
  let chunckOfRooms4 = Array.from((usePublicRooms(4).results));
  let chunckOfRooms5 = Array.from((usePublicRooms(5).results));
  let publicRooms = [];

  // If all the sets are received, compare each set with the one before it. If their first element are equal, the sets are equal -> the latter array can be empty. 
  if (chunckOfRooms0.length > 0 && chunckOfRooms1.length > 0 && chunckOfRooms2.length > 0 && chunckOfRooms3.length > 0 && chunckOfRooms4.length > 0 && chunckOfRooms5.length > 0) {
    if (chunckOfRooms4[0].id == chunckOfRooms5[0].id ) { chunckOfRooms5 = []; }
    if (chunckOfRooms3[0].id == chunckOfRooms4[0].id ) { chunckOfRooms4 = []; }
    if (chunckOfRooms2[0].id == chunckOfRooms3[0].id ) { chunckOfRooms3 = []; }
    if (chunckOfRooms1[0].id == chunckOfRooms2[0].id ) { chunckOfRooms2 = []; }
    if (chunckOfRooms0[0].id == chunckOfRooms1[0].id ) { chunckOfRooms1 = []; }
    publicRooms = chunckOfRooms0.concat(chunckOfRooms1, chunckOfRooms2, chunckOfRooms3, chunckOfRooms4, chunckOfRooms5);
  }

  const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);

  let regionCode = localStorage.getItem("__selected_country"); //LOCAL_STORAGE_KEY

  let setRegionCode = new URL(window.location).searchParams.get("region"); // If url includes queryparameter ?region=..., adjust the regionCode.
  if (setRegionCode) {
    setRegionCode = setRegionCode.toUpperCase();
    let countriesCollection = document.getElementsByClassName("option");
    let countries = Array.from(countriesCollection);
    for (let i = 0; i < countries.length; i++) { // For loop to check if the entered text is an actual region tag.
      const country = countries[i].id;
      if (country == setRegionCode) {
        regionCode = setRegionCode; // Change the regioncode to the entered region tag.
        localStorage.setItem("__selected_country", country);
      }
    }
  }

  let showEvents = new URL(window.location).searchParams.get("show"); // If url includes queryparameter ?show=events, set the variable to true.
  if (showEvents) { showEvents = showEvents.toLowerCase(); }

  const unfilteredPublicRooms = publicRooms;
  let filteredPublicRooms = unfilteredPublicRooms.filter(filterByRegionCode); // List of all the rooms with the active region code.

  function filterByRegionCode(room) { // Filtering on events later integrated.
    const roomdescription = room.description;
    if (roomdescription) {
      roomdescription.toUpperCase(); // To make it case insensitive. 
      if (showEvents == "events") {
        if (roomdescription.includes("#" + regionCode) && roomdescription.includes("#EVENT")) { return room; } // If in events tab, show rooms for that region.
      } else {
        if (roomdescription.includes("#" + regionCode) && !roomdescription.includes("#EVENT")) { return room; } // If in normal public rooms tab, show rooms for that region that are not an event.
      }
    } else {
      if (regionCode == "INTERNATIONAL") { return room; }; // If region code is INTERNATIONAL, display also the rooms that don't have a room code / description set.
    }
  }
    
  function changeRegion() {       // Show the preHomepagePopup.
    document.getElementById("preHomepagePopup").style.display = "block";
    document.getElementById("regionSelection").style.display = "block";
  }
  
  // End preHomepagePopup screen


  // Log in protection            Nigel.
  // Check if you're signed in. If you are, there's no need for extra room filtering.
  // If you're NOT signed in, the public rooms need to be filtered on description again. This filter only returns rooms that don't include "requireAccount".

  if (!auth.isSignedIn) {
    filteredPublicRooms = filteredPublicRooms.filter(filterByRequireAccount);
  }

  function filterByRequireAccount(room) {
    const roomdescription = room.description;
    if (roomdescription) {
      roomdescription.toUpperCase(); // To make it case insensitive. 
      return !roomdescription.includes("requireAccount");
    }
    else {
      return room; // If the rooms doesn't have a description, it can't have the "requireAccount" account tag, so show the room.
    }
  }

  // End log in protection


  // Auto-sort functionality        Nigel.

  function checkNameOverlap(room, roomB) {
    let str1, str2; // If the name contains a space, grab the first part before the space. Otherwise grab the entire name.
    if (room.indexOf(' ') >= 0) {
      str1 = room.substring(0, room.indexOf(' '));
    } else { str1 = room; }
    if (roomB.indexOf(' ') >= 0) {
      str2 = roomB.substring(0, roomB.indexOf(' '));
    } else { str2 = roomB; }

    if (str1 == str2) { return true; } 
    else { return false; }
  }

  function roomClick(room) {
    if (room.member_count >= room.room_size ) { // If the clicked room is full, check if there is a different room for the region, with the same scene, (partially) same room name, but different id. If there is space in there, send the user there. Otherwise let them through.
      for (let i = 0; i < filteredPublicRooms.length; i++) {
        const roomB = filteredPublicRooms[i];
        if (roomB.scene_id == room.scene_id && roomB.id !== room.id) { // The room ids must differ, so that you don't get sent to the innitial room.
          if (checkNameOverlap(room.name, roomB.name)) { // Check if the rooms have the same (beginning of the) name.
            if (roomB.member_count < roomB.room_size) { // The potential replacement must have space.
              console.log("The clicked room is full, sent to " + roomB.id);
              document.location = roomB.url;
              return;
            }
          }
        }
        if (i == (filteredPublicRooms.length -1)) { // If the last room in the list rooms for the region is also not a copy of the room that has space for another user, let the user join the lobby of the innitial room.
          console.log("There is no exact room copy that has space, so allowed to join the lobby of " + room.id);
          document.location = room.url;
        }
        
      }
    } else {
      document.location = room.url; // If the clicked room is not full, let the user join the room. 
    }
  }

  // End auto-sort functionality


  const wrapInBold = chunk => <b>{chunk}</b>;
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      qs.delete("new");
      createAndRedirectToNewHub(null, null, true, qs);
    }
  }, []);

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  const email = auth.email;


  // Events            Nigel.

  // For the events tab, we want the rooms that belong to a single event to be placed next to each other.
  // Therefore we create an object from filteredPublicRooms that groups the rooms if they have the same name. 
  let filteredPublicRoomsGrouped = filteredPublicRooms.reduce(function (r, a) {
    r[a.name] = r[a.name] || [];
    r[a.name].push(a);
    return r;
  }, Object.create(null));

  let topconAdmin = false; // To potentially show the Create Event button. Nigel.
  if (email) {
    if (email.includes("topconvip@") || auth.email.includes("@boldly-xr.com")) {
      topconAdmin = true;
    }
  }

  let showEventWizard = new URL(window.location).searchParams.get("create"); // If url includes queryparameter ?show=events, set the variable to true.

  // End Events.


  return (
    <PageContainer className={styles.homePage}>
      <Container>
        <div className={styles.hero}>
          {auth.isSignedIn ? (
            <div className={styles.signInContainer}>
              <span>
                <FormattedMessage
                  id="header.signed-in-as"
                  defaultMessage="Signed in as {email}"
                  values={{ email: maskEmail(email) }}
                />
              </span>
              <a href="#" onClick={auth.signOut} className={styles.mobileSignOut}>
                <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />
              </a>
            </div>
          ) : (
            <SignInButton mobile />
          )}
          <div className={styles.logoContainer}>
            <AppLogo />
          </div>
          <div className={styles.appInfo}>
            <div className={styles.appDescription}>{configs.translation("app-description")}</div>
            {/* Nigel //////////////////////////////////////////////////////////////////////////////// */}
            {/* {canCreateRooms && <CreateRoomButton />} */}
            {auth.isSignedIn && (
              <CreateHomepageDropdown/>
            )}
            <CreateHomepageDropdownTutorials/>
            {/* <CreateHomepageButton defaultMessage={"Quick Tutorial"} url={"https://www.youtube.com/playlist?list=PLk58K5q-RaTGRqe3pfzjEkujSl_OUh-iX"}/> */}
            {/* ////////////////////////////////////////////////////////////////////////////////////// */}
            {/* <PWAButton /> */}
          </div>
          <div className={styles.heroImageContainer}>
            <img
              alt={intl.formatMessage(
                {
                  id: "home-page.hero-image-alt",
                  defaultMessage: "Screenshot of {appName}"
                },
                { appName: configs.translation("app-name") }
              )}
              src={configs.image("home_background")}
            />
          </div>
        </div>
      </Container>
      {configs.feature("show_feature_panels") && (
        <Container className={classNames(styles.features, styles.colLg, styles.centerLg)}>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_rooms_thumb")} />
            <h3>
              <FormattedMessage id="home-page.rooms-title" defaultMessage="Instantly create rooms" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.rooms-blurb"
                defaultMessage="Share virtual spaces with your friends, co-workers, and communities. When you create a room with Hubs, you’ll have a private virtual meeting space that you can instantly share <b>- no downloads or VR headset necessary.</b>"
                values={{ b: wrapInBold }}
              />
            </p>
          </Column>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_communicate_thumb")} />
            <h3>
              <FormattedMessage id="home-page.communicate-title" defaultMessage="Communicate and Collaborate" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.communicate-blurb"
                defaultMessage="Choose an avatar to represent you, put on your headphones, and jump right in. Hubs makes it easy to stay connected with voice and text chat to other people in your private room."
              />
            </p>
          </Column>
          <Column padding gap="xl" className={styles.card}>
            <img src={configs.image("landing_media_thumb")} />
            <h3>
              <FormattedMessage id="home-page.media-title" defaultMessage="An easier way to share media" />
            </h3>
            <p>
              <FormattedMessage
                id="home-page.media-blurb"
                defaultMessage="Share content with others in your room by dragging and dropping photos, videos, PDF files, links, and 3D models into your space."
              />
            </p>
          </Column>
        </Container>
      )}
      {/* Nigel //////////////////////////////////////////////////////////////////////////////// */}
      {/* {sortedPublicRooms.length > 0 && ( */}
      <Container className={styles.roomsContainer}>
          <Container className={styles.roomsTitles}>
            <a href='/'>
              {showEvents !== "events" ? (
                <h3 className={styles.roomsHeading}>
                  <FormattedMessage id="home-page.public--rooms" defaultMessage="Public Rooms" /> 
                </h3> 
              ) : (
                <h3 className={styles.roomsHeadingUnactive}>
                  <FormattedMessage id="home-page.public--rooms" defaultMessage="Public Rooms" /> 
                </h3> 
              )}
            </a>
            <a href='/?show=events'>
              {showEvents !== "events" ? (
                <h3 className={styles.roomsHeadingUnactive}>Events</h3>
              ) : (
                <h3 className={styles.roomsHeading}>Events</h3>
              )}
            </a>
            {topconAdmin && ( // If you're a Topcon admin, show the button to create an event. 
              <a href='/?create=event'>
                <h3 className={styles.roomsHeadingUnactive}>Create Event</h3>
              </a>
            )}
          </Container>
          <Column grow padding className={styles.rooms}>
            {showEvents !== "events" ? (
              <MediaGrid center>
                {filteredPublicRooms.map(room => {      // sortedPublicRooms
                  return (
                    <MediaTile
                      key={room.id}
                      entry={room}
                      processThumbnailUrl={(entry, width, height) =>
                        scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                      }
                      onClick={() => {roomClick(room)}}
                    />
                  );
                })}
                {filteredPublicRooms.length < 1 && (
                  <span>No rooms available for this region.</span>
                )}
              </MediaGrid>
            ) : (
              <div>
                {Object.keys(filteredPublicRoomsGrouped).map((key) => ( // For the events tab, create a mediagrid for every group of rooms (event) and then create a mediatile for every room in that group.
                  <MediaGrid center key={key}>
                    {filteredPublicRoomsGrouped[key].map(room => { 
                      return (
                        <MediaTile
                          key={room.id}
                          entry={room}
                          processThumbnailUrl={(entry, width, height) =>
                            scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                          }
                          onClick={() => {roomClick(room)}}
                        />
                      );
                    })}
                  </MediaGrid>
                ))}
                {filteredPublicRooms.length < 1 && (
                  <span>No event rooms available for this region.</span>
                )}
              </div>
            )}
          </Column>
          <Container className={styles.roomsUnderTitles}>
            <h3 className={styles.regionCode}>#{regionCode}</h3>
            <h3 className={styles.changeRegion} onClick={changeRegion}>Change region</h3>
          </Container>
        </Container>
      {/* )} */}
      {/* ////////////////////////////////////////////////////////////////////////////////////// */}
      {sortedFavoriteRooms.length > 0 && (
        <Container className={styles.roomsContainer}>
          <h3 className={styles.roomsHeading}>
            <FormattedMessage id="home-page.favorite-rooms" defaultMessage="Favorite Rooms" />
          </h3>
          <Column grow padding className={styles.rooms}>
            <MediaGrid center>
              {sortedFavoriteRooms.map(room => {
                return (
                  <MediaTile
                    key={room.id}
                    entry={room}
                    processThumbnailUrl={(entry, width, height) =>
                      scaledThumbnailUrlFor(entry.images.preview.url, width, height)
                    }
                  />
                );
              })}
            </MediaGrid>
          </Column>
        </Container>
      )}
      {isHmc() ? (
        <Column center>
          <SocialBar />
        </Column>
      ) : null}
      {/* Nigel. */}
      {showEventWizard == "event" && (
        <CreateEventWizard />
      )}
    </PageContainer>
  );
}
