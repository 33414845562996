import React from "react";
import { useCssBreakpoints } from "react-use-css-breakpoints";
import styles from "./CreateEventWizard.scss";
import { TextInputField } from "../react-components/input/TextInputField";
import { Button } from "../react-components/input/Button";
import { createAndRedirectToNewHub } from "../utils/phoenix-utils";

export function CreateEventWizard() {
  const breakpoint = useCssBreakpoints();

  let scenes = [{id: 0, name: "Exhibition", scene_id:"xspHHHz"}, {id: 1, name: "Meeting Room", scene_id: "wt6B2Hc"}, {id: 2, name: "Auditorium", scene_id: "CWimjGB"}, {id: 3, name: "Social Plaza", scene_id: "swJKsvL"}];
  let amountsForRooms = [{id: 0, name: "1 room"}, {id: 1, name: "2 rooms"}, {id: 2, name: "3 rooms"}, {id: 3, name: "4 rooms"}];

  const regionCode = localStorage.getItem("__selected_country");
  let selectedName = null, selectedScene = null, selectedAmountRooms = null;

  function selectScene(buttonId, sceneId) {
    selectedScene = sceneId;

    let sceneButtons = Array.from(document.querySelectorAll("#sceneButton"));
    for (let i = 0; i < sceneButtons.length; i++) {
      sceneButtons[i].classList.remove("active-button"); // Make all the scene buttons inactive.
    }
    sceneButtons[buttonId].classList.add("active-button"); // Make the clicked button active.
  }

  function selectAmountRooms(buttonId) {
    selectedAmountRooms = buttonId + 1;

    let amountButtons = Array.from(document.querySelectorAll("#amountButton"));
    for (let i = 0; i < amountButtons.length; i++) {
      amountButtons[i].classList.remove("active-button"); // Make all the amount buttons inactive.
    }
    amountButtons[buttonId].classList.add("active-button"); // Make the clicked button active.
  }

  function addAdditionalResultText() {
    document.getElementById("dialogResult").children[0].innerHTML += "<br><br><b>Important:</b> copy these event room url's and hold on to them, since they are private for now. <br>"
      + "You can enter these rooms and set them to public in the room settings when you wish. After that they will appear on the event page of your region.<br></br>"
      + "<b>Event landing page</b><br>Now it's time for you to create a landing page on which users can sign up for your event.<br></br>"
      + "Make sure you've received the credentials to enter the shared <a href='https://app.unbounce.com/users/sign_in' target='_blank' rel='noopener noreferrer'>Unbounce</a> account from the Topcon VIP team. " 
      + "Sign in, choose one of the three templates for the landing page, customize it as you wish and host it.<br></br>"
      + "We advice referring to the event page url for your region, so that the users end up on a page listing the currently available event rooms. Url: " 
      + "<a href='https://topcon-vip.com/?region=" + regionCode + "&show=events' target='_blank' rel='noopener noreferrer'>https://topcon-vip.com/?region=" + regionCode + "&show=events</a><br></br>"
      + "Good luck and have fun in your event! <a href='/?show=event'>Done</a>";
  }

  function createEventRooms() {
    if (selectedAmountRooms > 4) { selectedAmountRooms = 4; } // To make sure no more than 4 rooms are being created.
    createAndRedirectToNewHub(selectedName, selectedScene, true, false, true).then( // Last parameter is true, to indicate it's an event. 
      function(value) {
        // Add the created link to the result window.
        document.getElementById("dialogResult").children[0].innerHTML += "<br> - <a href=" + value + " target='_blank' rel='noopener noreferrer'>" + value + "</a>" ;

        selectedAmountRooms--;
        if (selectedAmountRooms > 0) { // If there's another rooms te be made still, wait a few seconds and retrigger this function. 
          setTimeout(() => {
            createEventRooms();
          }, 2500);
        } else { 
          addAdditionalResultText(); // If this was the last room te make, add the additional text to the result window.
        }
      },
      function(error) {console.log(error);}
    );
  }

  function createEvent() {
    selectedName = document.getElementsByTagName("input")[0].value;
    selectedName = selectedName.replace(/[\/\\,$~'"*<>{}]/g, ''); // 'Escape' the user text input.
    if (!selectedName) {
      alert("Please enter an event name.");
      return;
    }
    if (!selectedScene) {
      alert("Please choose an environment.");
      return;
    }
    if (!selectedAmountRooms) {
      alert("Please choose an amount of rooms.");
      return;
    }

    document.getElementById("dialogContent").style.opacity = 0.5;
    document.getElementById("dialogResult").style.display = "block";

    createEventRooms();    
  }

  return (
    <div className={styles.dialogOverlay}>
      <div className={styles.dialog}>
        <div className={styles.dialogContent} id="dialogContent">
          <h3 className={styles.dialogTitle}>Let's create your event!</h3>

            <div className={styles.dialogSection}>
              <div className={styles.sectionHeading}>
                Event name
              </div>
              <div className={styles.sectionContent}>
                What is your event's name?
                <TextInputField
                  className={styles.textInputField}
                  description="Keep it short but powerful!"
                />
                </div>
            </div>

            <div className={styles.dialogSection}>
              <div className={styles.sectionHeading}>
                Virtual environment
              </div>
              <div className={styles.sectionContent}>
                Which environment would you like your event to take place in?
                <div className={styles.buttons}>
                  {scenes.map(scene => {
                    return (
                      <Button
                        id="sceneButton"
                        key={scene.id}
                        className={styles.optionsButtons}
                        thick
                        xl={breakpoint !== "sm" && breakpoint !== "md"}
                        preset="landing"
                        onClick={e => {
                          e.preventDefault();
                          selectScene(scene.id, scene.scene_id);
                        }}
                      >
                        {scene.name}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={styles.dialogSection}>
              <div className={styles.sectionHeading}>
                Amount of rooms
              </div>
              <div className={styles.sectionContent}>
                How many rooms do you need? A room can contain up to 24 users.
                <div className={styles.buttons}>
                  {amountsForRooms.map(number => {
                    return (
                      <Button
                        id="amountButton"
                        key={number.id}
                        className={styles.optionsButtons}
                        thick
                        xl={breakpoint !== "sm" && breakpoint !== "md"}
                        preset="landing"
                        onClick={e => {
                          e.preventDefault();
                          selectAmountRooms(number.id)
                        }}
                      >
                        {number.name}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={styles.submitButtons}>
              <Button preset="landing" onClick={createEvent} id="button" className={styles.submitButton}>
                Create event
              </Button>
              <a href="/?show=events">
                <Button preset="signin" className={styles.submitButton}>
                  Cancel
                </Button>
              </a>
            </div>

        </div>

        <div className={styles.dialogResult} id="dialogResult">
          <div className={styles.dialogResultContent}>
            <div className={styles.sectionHeading}>
              Awesome! We're creating the event rooms.
            </div>
            Event room(s):
          </div>
        </div>

      </div>
    </div>
  );
}